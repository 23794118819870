@font-face {
    font-family: 'ABgilroy';
    fontStyle: normal;
    fontWeight: normal;
    src: url('./ABgilroy/ABgilroy-regular.otf') format('truetype');
}

@font-face {
    font-family: 'ABgilroy';
    font-style: italic;
    font-weight: normal;
    src: url('./ABgilroy/ABgilroy-regularitalic.otf') format('truetype');
}

@font-face {
    font-family: 'ABgilroy';
    font-style: normal;
    font-weight: bold;
    src: url('./ABgilroy/ABgilroy-bold.otf') format('truetype');
}

@font-face {
    font-family: 'ABgilroy';
    font-style: italic;
    font-weight: bold;
    src: url('./ABgilroy/ABgilroy-bolditalic.otf') format('truetype');
}

@font-face {
    font-family: 'ABgilroy';
    font-style: normal;
    font-weight: 500;
    src: url('./ABgilroy/ABgilroy-medium.otf') format('truetype');
}

@font-face {
    font-family: 'ABgilroy';
    font-style: italic;
    font-weight: 500;
    src: url('./ABgilroy/ABgilroy-mediumitalic.otf') format('truetype');
}